import './App.css';
import Header from "./Header";
import SeparatorPictures from "./SeparatorPictures";
import About from "./About";
import References from "./References";
import Contact from "./Contact";
import Footer from "./Footer";
import {useEffect, useState} from "react";

function App() {

    const [scrolledHeader, setScrolledHeader] = useState(false)

    const handleScroll = event => {
        const positionY = window.scrollY
        return (positionY >= 25) ? setScrolledHeader(true) : setScrolledHeader(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true})

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    const handleLink = (s) => {
        const sectionY = document.getElementById(s)?.offsetTop
        if (s === '#') {
            window.scroll({top: 0, behavior: 'smooth'});
        } else {
            if (sectionY !== undefined) {
                window.scroll({top: sectionY - 75, behavior: 'smooth'});
            } else {
                window.scroll({top: 0, behavior: 'smooth'});
            }
        }

    }


    return (
        <div className="app">
            <Header handleLink={handleLink} scrolledHeader={scrolledHeader} />
            <SeparatorPictures />
            <About />
            <References />
            <Contact />
            <Footer />

            <div className={`page-top ${scrolledHeader ? 'visible': ''}`} onClick={() => handleLink('#')}>
                <img src='/icons/9110919_circle_chevron_top_icon.svg' alt='' />
            </div>

        </div>);
}

export default App;
