
import './Contact.css'
import {useTranslation} from "react-i18next";

export default function Contact() {
    const { t } = useTranslation();

    return (
        <>
            <div id='contact'>
                <div className='container'>
                    <h2>{t('contact')}</h2>
                    <div className='contact-container'>
                        <form>
                            <input placeholder={t('name')}/>
                            <input placeholder={t('mail')}/>
                            <input placeholder={t('phone')}/>
                            <input placeholder={t('subject')}/>
                            <textarea placeholder={t('message')}/>

                            <button>{t('send')}</button>
                        </form>
                        <div className='logo-container'>
                            <img src='/icons/logo.png' alt='' />

                            <div className='social-container'>
                                <a href='https://www.facebook.com/ExtrasCastingAgency' target='_blank'>
                                    <img src='/icons/2959749_facebook_icon.svg' alt=''/>
                                </a>
                                <a href='https://www.instagram.com/extrascastinghungary' target='_blank'>
                                    <img src='/icons/1161953_instagram_icon.png' alt=''/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}