import image1 from './slide-1.jpg'
import image3 from './slide-3.jpg'
import image5 from './slide-5.jpg'
import image7 from './slide-7.jpg'
import image8 from './slide-8.jpg'
import image9 from './slide-9.jpg'
import image11 from './slide-11.jpg'
import image12 from './slide-12.jpg'
import image13 from './slide-13.jpg'
import image14 from './slide-14.jpg'
import image15 from './slide-15.jpg'
import image16 from './slide-16.jpg'
import image17 from './slide-17.jpg'
import image18 from './slide-18.jpg'
import image19 from './slide-19.jpg'
import image21 from './slide-21.jpg'
import image22 from './slide-22.jpg'
import image23 from './slide-23.jpg'
import image24 from './slide-24.jpg'
import image25 from './slide-25.jpg'
import image26 from './slide-26.jpg'
import image27 from './slide-27.jpg'
import image28 from './slide-28.jpg'
import image30 from './slide-30.jpg'
import image32 from './slide-32.jpg'
import image33 from './slide-33.jpg'
import image35 from './slide-35.jpg'
import image36 from './slide-36.jpg'
import image37 from './slide-37.jpg'
import image38 from './slide-38.jpg'
import image41 from './slide-41.jpg'
import image42 from './slide-42.jpg'
import image43 from './slide-43.jpg'
import image46 from './slide-46.jpg'
import image47 from './slide-47.jpg'
import image49 from './slide-49.jpg'
import image50 from './slide-50.jpg'
import image51 from './slide-51.jpg'
import image52 from './slide-52.jpg'
import image53 from './slide-53.jpg'
import image54 from './slide-54.jpg'

export default [
    image1, image3, image5, image7, image8, image9,
    image11, image12, image13, image14, image15, image16, image17, image18, image19,
    image21, image22, image23, image24, image25, image26, image27, image28, image30,
    image32, image33, image35, image36, image37, image38,
    image41, image42, image43, image46, image47, image49, image50,
    image51, image52, image53, image54]

