
import './About.css'
import {useTranslation} from "react-i18next";

export default function About() {
    const { t } = useTranslation();

    return (
        <>
            <div id='about'>
                <div className='container'>
                    <div className='about-container'>
                        {/*<div className='image-container'>
                            <img src='/pictures/about-picture.jpg' alt='' />
                        </div>*/}
                        <div className='text-container'>
                            <h1>{t('about')}</h1>
                            <p>{t('aboutLine1')}</p>
                            <p>{t('aboutLine2')}</p>
                            <p>{t('aboutLine3')}</p>
                            <ul>
                                <li>{t('aboutList1')}</li>
                                <li>{t('aboutList2')}</li>
                                <li>{t('aboutList3')}</li>
                                <li>{t('aboutList4')}</li>
                            </ul>
                            <p>{t('aboutLine4')}</p>
                            <p>{t('aboutLine5')}</p>
                            <p>{t('aboutLine6')}</p>
                        </div>
                    </div>
                    <div className='counter-container'>
                        <div className='counter'>
                            <img src='/icons/2199113_announcement_advertising_bullhorn_loud_megaphone_icon.svg' alt=''/>
                            <p className='counter-number'>2M+</p>
                            <p className='counter-text'>{t('background_artists_workhours')}</p>
                        </div>
                        <div className='counter'>
                            <img src='/icons/2199115_video_camera_film_media_movie_icon.svg' alt=''/>
                            <p className='counter-number'>100+</p>
                            <p className='counter-text'>{t('productions')}</p>
                        </div>
                        <div className='counter'>
                            <img src='/icons/2199084_profile_account_avatar_person_user_icon.svg' alt='' />
                            <p className='counter-number'>45k+</p>
                            <p className='counter-text'>{t('background_artists_2')}</p>
                        </div>
                        <div className='counter'>
                            <img src='/icons/2199108_calendar_date_day_event_schedule_icon.svg' alt=''/>
                            <p className='counter-number'>24/7</p>
                            <p className='counter-text'>{t('availability')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}