
import './Header.css'
import {useTranslation} from "react-i18next";
import ImageSlider from "./ImageSlider";

import {Button} from "@mui/material";
import {localStorageGetItem, localStorageSetItem} from "../utils";
import {defaultLang} from "../locales";
import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import SlideImages from "../images/SlideImages";

const lng = localStorageGetItem('i18nextLng', defaultLang.value)

export default function Header({scrolledHeader, handleLink}) {
    const { t } = useTranslation();

    const [currentLang, setCurrentLang] = useState(lng)
    const changeLanguage = (lang) => {
        setMobileMenuOpen(false)
        setCurrentLang(lang)
        localStorageSetItem('i18nextLng', lang)
        window.location.reload();
    }

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const handleMobileMenuClick = (s) => {
        setMobileMenuOpen(false)
        handleLink(s)
    }

    return (
        <div id='header'>
            <div className='container'>
                <div className='menu-container'>
                    <div className='menu-inner-container'>
                        <img className={(scrolledHeader) ? 'active' : ''} onClick={() => handleLink('#')} src='/icons/logo-sized.png' alt='logo' />
                        <div className='menu-item-container'>
                            <ul>
                                <li onClick={() => handleLink('about')}>{t('about')}</li>
                                <li onClick={() => handleLink('references')}>{t('references')}</li>
                                <li onClick={() => handleLink('contact')}>{t('contact')}</li>
                            </ul>
                            <div className='lang-container'>
                                <Button className={(currentLang === 'hu') ? 'active' : ''}
                                        onClick={() => changeLanguage('hu')}>HU</Button>
                                <div className='lang-separator'/>
                                <Button className={(currentLang === 'en') ? 'active' : ''}
                                        onClick={() => changeLanguage('en')}>EN</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='menu-small'>
                    <div className='logo-container'>
                        <img src='/icons/logo.png' alt='logo'/>
                    </div>
                    <div className='mobile-menu'>
                        <img src='/icons/326672_menu_icon.svg' alt='' onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}} />
                        <div className={`mobile-menu-item-container ${mobileMenuOpen ? 'open' : ''}`}>
                            <div className='mobile-menu-item-inner-container'>
                                <ul>
                                    <li onClick={() => handleMobileMenuClick('about')}>{t('about')}</li>
                                    <li onClick={() => handleMobileMenuClick('references')}>{t('references')}</li>
                                    <li onClick={() => handleMobileMenuClick('contact')}>{t('contact')}</li>
                                </ul>
                                <div className='lang-container'>
                                    <Button className={(currentLang === 'hu') ? 'active' : ''}
                                            onClick={() => changeLanguage('hu')}>HU</Button>
                                    <div className='lang-separator'/>
                                    <Button className={(currentLang === 'en') ? 'active' : ''}
                                            onClick={() => changeLanguage('en')}>EN</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='header-container'>
                    <div className='logo-container'>
                        <img src='/icons/logo-sized.png' alt='logo'/>
                    </div>
                    <div className='slider-container'>
                        <ImageSlider images={SlideImages}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {
    scrolledHeader: PropTypes.bool,
    handleLink: PropTypes.func
};
