'use client'

import PropTypes from "prop-types";
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {useLocales} from "./use-locales";

export default function LocalizationProvider({children}) {
    const { currentLang } = useLocales()

    return (
        <MuiLocalizationProvider adapterLocale={currentLang.adapterLocale}>
            {children}
        </MuiLocalizationProvider>
    )
}

LocalizationProvider.propTypes = {
    children: PropTypes.node,
}