
import './Footer.css'
import {useTranslation} from "react-i18next";


export default function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <div id='footer'>
                <div className='container'>
                    <p className='footer-text'>{t('footerText')}</p>
                </div>
            </div>
        </>
    )
}