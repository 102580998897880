import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './sections/App';
import {LocalizationProvider} from "./locales";

import './locales/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <LocalizationProvider>
          <App />
      </LocalizationProvider>
  </React.StrictMode>
);
