
import './SeparatorPictures.css'

export default function SeparatorPictures() {

    return (
        <>
            <div className='separator-image-container'>
                <img src='/pictures/fekete_feher.png' alt='' />
            </div>
        </>
    )
}