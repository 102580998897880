'use client'

import merge from 'lodash/merge';
import {enUS as enUSAdapter, hu as huHUAdapter} from 'date-fns/locale'
import {enUS as enUSCore, huHU as huHUCore} from '@mui/material/locale'

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: merge(enUSCore),
        adapterLocale: enUSAdapter,
        icon: 'flagpack:gb-nir',
    }, {
        label: 'Magyar',
        value: 'hu',
        systemValue: merge(huHUCore),
        adapterLocale: huHUAdapter,
        icon: 'flagpack:hu',
    }
]

export const defaultLang = allLangs[1]