'use client'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { defaultLang } from './config-langs';
import translationEn from './langs/en.json';
import translationHu from './langs/hu.json'
import {localStorageGetItem} from "../utils";

const lng = localStorageGetItem('i18nextLng', defaultLang.value)

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translations: translationEn },
            hu: { translations: translationHu }
        },
        lng,
        fallbackLng: lng,
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
        }
    })

export default i18n