
import './References.css'
import {useTranslation} from "react-i18next";
import Slider from "react-slick";

const productions = [
    {image: 'Poor-Things.png', link: 'https://www.imdb.com/title/tt14230458/?ref_=nv_sr_srsg_0_tt_7_nm_1_in_0_q_poor%2520things', name: 'Poor Things', rating: 7.8},
    {image: 'Hunters.jpg', link: 'https://www.imdb.com/title/tt7456722/?ref_=nv_sr_srsg_0_tt_7_nm_1_in_0_q_Hunters', name: 'Hunters', rating: 7.2},
    {image: 'Lee.png', link: 'https://www.imdb.com/title/tt5112584/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Lee', name: 'Lee', rating: 6.5},
    {image: 'Continental.png', link: 'https://www.imdb.com/title/tt6486762/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Continental', name: 'The Continental', rating: 7.2},
    {image: 'Colette.jpg', link: 'https://www.imdb.com/title/tt5437928/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Colette', name: 'Colette', rating: 6.7},
    {image: 'Radioactive.jpg', link: 'https://www.imdb.com/title/tt6017756/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Radioactive', name: 'Radioactive', rating: 6.3},
    {image: 'The-Witcher.jpg', link: 'https://www.imdb.com/title/tt5180504/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_the%2520witcher', name: 'The Witcher', rating: 8.0},
    {image: 'The-King.jpg', link: 'https://www.imdb.com/title/tt7984766/?ref_=nv_sr_srsg_5_tt_6_nm_0_in_0_q_the%2520king', name: 'The King', rating: 7.3},
    {image: 'Genius.jpg', link: 'https://www.imdb.com/title/tt6812028/', name: 'Picasso', rating: 7.5},
    {image: 'Red-Sparrow.jpg', link: 'https://www.imdb.com/title/tt2873282/?ref_=nv_sr_srsg_0_tt_6_nm_2_in_0_q_red%2520sparrow', name: 'Red Sparrow', rating: 6.6},

    {image: 'alienist.jpg', link: 'https://www.imdb.com/title/tt4604612', name: 'The Alienist', rating: 7.7},
    {image: 'crown.jpg', link: 'https://www.imdb.com/title/tt4786824', name: 'The Crown', rating: 8.6   },
    {image: 'terror.jpg', link: 'https://www.imdb.com/title/tt2708480', name: 'The Terror', rating: 7.9},
    {image: '6u.jpg', link: 'https://www.imdb.com/title/tt8106534   ', name: '6 Underground', rating: 6.1},
    {image: 'jacob.jpg', link: 'https://www.imdb.com/title/tt0071688', name: 'Jakob der Lügner', rating: 7.1},
    {image: 'napol.jpg', link: 'https://www.imdb.com/title/tt0253839', name: 'Napoléon', rating: 7.3},
    {image: 'hback.jpg', link: 'https://www.imdb.com/title/tt0119329', name: 'The Hunchback', rating: 6.1},
    {image: 'rasputin.jpg', link: 'https://www.imdb.com/title/tt0117442', name: 'Rasputin', rating: 6.9},
    {image: 'munich.jpg', link: 'https://www.imdb.com/title/tt0408306', name: 'Munich', rating: 7.5},
    {image: 'laraffle.jpg', link: 'https://www.imdb.com/title/tt1382725', name: 'La rafle', rating: 7.1},

    {image: 'fbi.jpg', link: 'https://www.imdb.com/title/tt14449470', name: 'FBI: International', rating: 6.5},
    {image: 'fear.jpg', link: 'https://www.imdb.com/title/tt1967620', name: 'The Fear Index', rating: 5.9},
    {image: 'birds.jpg', link: 'https://www.imdb.com/title/tt11771006', name: 'Birds of Paradise', rating: 5.9},
    {image: 'north.jpg', link: 'https://www.imdb.com/title/tt7660970', name: 'The North Water', rating: 7.6},
    {image: 'thesurvivor.jpg', link: 'https://www.imdb.com/title/tt9242528', name: 'The Survivor', rating: 6.7},
    {image: 'ransom.jpg', link: 'https://www.imdb.com/title/tt5809150', name: 'Ransom', rating: 6.6},
    {image: 'jackryan.jpg', link: 'https://www.imdb.com/title/tt5057054', name: 'Jack Ryan', rating: 8.0},
    {image: 'treadstone.jpg', link: 'https://www.imdb.com/title/tt8289480', name: 'Treadstone', rating: 6.9},
    {image: 'simone.png', link: 'https://www.imdb.com/title/tt13389730', name: 'Simone, le voyage du siècle', rating: 6.8},
    {image: 'mrsharris.jpg', link: 'https://www.imdb.com/title/tt5151570/', name: 'Mrs. Harris Goes to Paris', rating: 7.1},
]

function ReferenceNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{
            ...style,
            height: 'calc(100% - 3px)',
            'margin-top': '12px',
            right: 0,
            'z-index': 99,
            width: '50px',
            'align-content': 'center'
        }} onClick={onClick}>
            <img width='50px' src='/icons/211647_right_chevron_icon.svg' alt=''/>
        </div>
    );
}

function ReferencePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{
            ...style,
            height: 'calc(100% - 3px)',
            'margin-top': '12px',
            left: 0,
            'z-index': 99,
            width: '50px',
            'align-content': 'center'
        }} onClick={onClick}>
            <img width='50px' src='/icons/211646_left_chevron_icon.svg' alt=''/>
        </div>
    );
}

const settings = {
    centerMode: false,
    infinite: true,
    slidesToShow: 5,
    rows: 2,
    slidesPerRow: 1,
    nextArrow: <ReferenceNextArrow/>,
    prevArrow: <ReferencePrevArrow/>,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
            }
        },{
            breakpoint: 600,
            settings: {
                rows: 1,
                slidesToShow: 1,
            }
        }
    ]
}

export default function References() {
    const { t } = useTranslation();

    return (
        <>
            <div id='references'>
                <div className='container'>
                    <div className='references-container'>
                        <h2>{t('references')}</h2>

                        <div className='ref-slider-container'>
                            <Slider {...settings}>
                                {productions.map((item, index) => {
                                    return (
                                        <div key={index} className='ref-item'>
                                            <div className='ref-item-inner'>
                                                <img src={'/posters/' + item.image} alt=''/>
                                                <div className='overlay'>
                                                    <a href={item.link} target='_blank'>
                                                        <div className='overlay-image-container'>
                                                            <img src='/icons/9110810_external_link_icon.svg' alt=''/>
                                                        </div>
                                                        <div className='overlay-name-container'>
                                                            <span>{item.name || ''}</span>
                                                            <div className='overlay-rating-container'>
                                                                <img src='/icons/IMDb-Logo.wine.svg' alt=''/>
                                                                <span>{item.rating || ''}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>)
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

/*<div className='ref-item' key={index}>
    <img src={'/posters/' + item.image} alt='' />
    <div className='overlay'>
        <a href={item.link} target='_blank'>
            <div className='overlay-image-container'>
                <img src='/icons/9110810_external_link_icon.svg' alt=''/>
            </div>
            <div className='overlay-name-container'>
                <span>{item.name || ''}</span>
                <div className='overlay-rating-container'>
                    <img src='/icons/IMDb-Logo.wine.svg' alt=''/>
                    <span>{item.rating || ''}</span>
                </div>
            </div>
        </a>
    </div>
</div>*/